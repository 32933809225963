<template>
  <div class="anleftnav darkBack">
    <div>
      <p>{{ list[0].title }}</p>
      <p>
        <countTo
          :startVal="list[0].preNum"
          :endVal="list[0].num"
          :decimals="2"
          :duration="3000"
        ></countTo>
      </p>
    </div>
    <div>
      <p>{{ list[1].title }}</p>
      <p>
        <countTo
          :startVal="list[1].preNum"
          :endVal="list[1].num"
          :decimals="3"
          :duration="3000"
        ></countTo>
      </p>
    </div>
    <div>
      <p>{{ list[2].title }}</p>
      <p>
        <countTo
          :startVal="list[2].preNum"
          :endVal="list[2].num"
          :decimals="2"
          :duration="3000"
        ></countTo
        >%
      </p>
    </div>
    <div>
      <p>{{ list[3].title }}</p>
      <p>
        <countTo
          :startVal="list[3].preNum"
          :endVal="list[3].num"
          :duration="3000"
        ></countTo
        >%
      </p>
    </div>
  </div>
</template>

<script>
import bus from "/src/assets/js/bus.js";
import lotteryjson from "/public/data/lottery.json";
import { config, upDataMap, ajaxRequest } from "../../../assets/js/config.js";
import { amap } from "../../../assets/js/mapVenues.js";

import { getAjax, timestamp } from "../../../assets/js/websocket";
// 引入数字滚动组件
import countTo from "vue-count-to";
export default {
  components: { countTo },
  data() {
    return {
      list: [
        { title: "体育场馆总面积", num: 0, preNum: 0 },
        { title: "人均体育场馆面积", num: 0, preNum: 0 },
        { title: "“一场两馆”覆盖率", num: 0, preNum: 0 },
        { title: "智慧化体育场馆比较", num: 0, preNum: 0 },
      ],
      venueList: [],
    };
  },
  methods: {
    getAreaData() {
      let that = this;
      let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
      let url;
      if (info.is_city == 1) url = "/map/screen/getSportsVenue";
      else url = "/map/screen/getSportsVenue?area_id=" + info.area_id;
      var data = {
        token: window.getToken(),
        app_id: window.appId,
        time: timestamp(),
      };
      getAjax({
        url: url,
        method: "GET",
        data: data,
        success: function (res) {
          // console.log(res)
          that.venueList = res.data;
          that.list[0].preNum = that.list[0].num;
          that.list[1].preNum = that.list[1].num;
          that.list[2].preNum = that.list[2].num;
          that.list[3].preNum = that.list[3].num;
          that.list[0].num = parseFloat(res.data[0].venue_area);
          that.list[1].num = parseFloat(res.data[0].ren_jun_chang_guan_mian_ji);
          that.list[2].num = parseFloat(res.data[0].fugailv);
          that.list[3].num = parseFloat(res.data[0].chang_guan_bijiao);

          lotteryjson.gymnasium.data.center = [
            res.data[0].lng,
            res.data[0].lat,
          ];
          // lotteryjson.gymnasium.data.zooms = [18,19]
          lotteryjson.gymnasium.data.points = [];
          res.data.forEach((item) => {
            lotteryjson.gymnasium.data.points.push({
              location: [item.lng, item.lat],
              name: item.title,
            });
			if(item.three_js_model) window.loadModel2(item.three_js_model);
          });
          // console.log(lotteryjson.gymnasium)
          that.plug.upDatavenues(lotteryjson.gymnasium);
        },
      });
    },
  },
  mounted() {
    window.windowResize();

    let that = this;
    this.getAreaData();

    bus.$on("venue_title", (title) => {
      // console.log(title)
      let that = this;
      let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
      let url;
      if (info.is_city == 1) url = "/map/screen/getSportsVenue";
      else url = "/map/screen/getSportsVenue?area_id=" + info.area_id;
      var data = {
        token: window.getToken(),
        app_id: window.appId,
        time: timestamp(),
      };
      getAjax({
        url: url,
        method: "GET",
        data: data,
        success: function (res) {
          // console.log(res)
          res.data.forEach((item) => {
            if (item.title == title) {
              that.list[0].num = parseFloat(item.venue_area);
              that.list[1].num = parseFloat(item.ren_jun_chang_guan_mian_ji);
              that.list[2].num = parseFloat(item.fugailv);
              that.list[3].num = parseFloat(item.chang_guan_bijiao);
            }
          });
        },
      });
    });
    // bus.$on("venue_num",res=>{
    // 	this.list[0].num = parseFloat(this.venueList[res].venue_area);
    // 	this.list[1].num = parseFloat(this.venueList[res].ren_jun_chang_guan_mian_ji);
    // 	this.list[2].num = parseFloat(this.venueList[res].fugailv);
    // 	this.list[3].num = parseFloat(this.venueList[res].chang_guan_bijiao);
    // })
  },
};
</script>

<style scoped>
.anleftnav{
    width:5.04rem;
    height:2.29rem;
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
    padding: 0.2rem;
    color:#A8DAEB;
    font-size: 0.14rem;
    text-align: center;
}
.anleftnav > div {
  width: 2rem;
  height: 0.78rem;
  background: url("../../../assets/images/borderBack.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 0.16rem;
  box-sizing: border-box;
}
.anleftnav > div > p:nth-child(2) {
  color: #20d7fe;
  font-size: 0.22rem;
}
</style>