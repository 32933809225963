<template>
    <!-- 场馆1 -->
    <div>
        <div class="goBack">
            <div @click="getBack('stadiumone')" style="cursor: pointer;">
                <img src="../../assets/images/return.png" alt="">
            </div>
            <span>{{pitchon}}</span>
        </div>

        <div class="posit-left">
            <sportscomplex></sportscomplex>
        </div>
        <div class="posit-right">
            <venuebooking></venuebooking>
        </div>
    </div>
</template>

<script>
import { show } from '../../assets/js/animate.js'
import sportscomplex from '../charts/stadiumtwo/sportscomplex.vue'
import venuebooking from '../charts/stadiumtwo/venuebooking.vue'
import bus from '/src/assets/js/bus.js'

export default {
    components: {
        sportscomplex,
        venuebooking,
    },
    data () {
        return {
            pitchon: "越城区体育中心",
        }
    },
    methods: {
        getBack(showdata){
            // let _this = this;
            // show(showdata, _this);
            // this.plug.upDataMap(window.lotteryjson.home)
            bus.$emit("backFather",true)
        },
    },
    mounted () {
        // console.log(window.venueName)
        this.pitchon = window.venueName;
    }
}
</script>

<style scoped>

</style>